import React, { Component } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import store from "../../redux/store";
import Page404 from "../template/Page404";

const RouteScrollingComponent = (props) => {
  const { component: Component, authorize = false, ...other } = props;
  let params, location, navigate;

  params = useParams();
  location = useLocation();
  navigate = useNavigate();
  // const key = props.key;
  //   console.debug(

  //   "\nRouteScrollingComponent props:", props,
  //   "\nRouteScrollingComponent key:", key,
  //   "\nRouteScrollingComponent params:", params,
  //   "\nRouteScrollingComponent location:", location
  //  )

  // get user organizations
  const userOrganizations = store?.getState()?.root?.user?.organizations;

  // if within authorized variant and organization slug is diffrent then any of user's organizations, return page 404
  if (
    authorize &&
    params &&
    params.organization &&
    !(
      Array.isArray(userOrganizations) &&
      userOrganizations.find((org) => org.url_slug === params.organization)
    )
  ) {
    return <Page404 />;
  }
  return (
    <Component
      {...{ ...other, location, navigate, match: { params } }}
    />
  );
};

/**
 * The component scrolls to top page when loaded or changed.
 * If hash passed as part of the route, it scrolls to the hash.
 *
 * kudos to https://stackoverflow.com/a/44438949/3770223
 */

class RouteScrollingComponentOld extends Component {
  hashCheckerInterval = null;

  state = {
    revealProps: {},
  };

  yOffset = process.env.REACT_APP_HASH_SCROLL_OFFSET || -180;

  componentDidMount() {
    // const { revealProps = {} } = this.props;
    // this.setState(revealProps);
    // // update head tags
    // // Service.updatePage()

    const { location } = this.props;

    if (!location) {
      console.debug("location not passed");
      return;
    }

    if (location.hash) {
      const { hash } = location;
      // console.debug("Going to hash:", hash.substr(1))
      this.scrollToHash(hash.substr(1));
    } else {
      // window.scrollTo({top:0, behavior: "smooth"})
    }
    // this.setBodyClass();
  }

  scrollToHash(hash) {
    //
    this.hashCheckerInterval = setInterval(() => {
      const el = document.getElementById(hash);
      // console.debug("checked hash target....", el)
      if (el) {
        try {
          // console.debug("found hash target:", el)

          // calculate offset position to scroll to
          const pos =
            el.getBoundingClientRect().top + window.pageYOffset + this.yOffset;

          // scroll
          window.scrollTo({ top: pos, behavior: "smooth" });
        } catch (e) {
          console.debug("Error scrolling to hash", e);
        }

        // clear the hash checker interval
        clearInterval(this.hashCheckerInterval);
      }
    }, 100);
  }

  setBodyClass() {
    // update background
    const { bodyClass = "bg-default" } = this.props;
    document.body.className = bodyClass;
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props;

    if (!location) {
      console.debug("location not passed");
      return;
    }

    // if (this.props.path === this.props.location.pathname && this.props.location.pathname !== prevProps.location.pathname) {
    // console.debug("checking location ", this.props.location.pathname, prevProps.location.pathname)
    if (location.pathname !== prevProps.location.pathname) {
      // console.debug("scrolling to top")
      window.scrollTo({ top: 0, behavior: "smooth" });
      // this.setState( { revealProps: {...this.props.revealProps, appear: true}})
    }

    // hash changed
    if (location.hash !== prevProps.location.hash) {
      const { hash } = location;
      console.log("Moving to hash", hash);
    }

    if (this.props.bodyClass !== prevProps.bodyClass) {
      this.setBodyClass();
    }
  }

  render() {
    console.debug("RouteScrollingComponent props\n", this);
    const { component: Component, ...other } = this.props;
    return <Component {...other} />;
  }
}

export default RouteScrollingComponent;
