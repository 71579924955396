import Base from './baseClientService';


const { REACT_APP_EB3000 = "http://localhost:32123/CheckEB3000Status", REACT_APP_EB3000_CONNECTED = "True" } = process.env;

class EB3000Service extends Base {
  static async checkIfConnected() {
    // try {
    
    // const result = await this.GET( REACT_APP_EB3000 );      
    // fetch and not axios BECAUSE OF ADDITIONAL HEADERS in axios!!!
    const result = await fetch( REACT_APP_EB3000 );      
    

    const answer = await result.text();

    console.debug("eb3000 connected:", answer, answer === REACT_APP_EB3000_CONNECTED);

    // check against "connected" value from .env (defaults to string 'True' )    
    return (answer === REACT_APP_EB3000_CONNECTED);

    // } catch (e) {
    //   this.Error(e.message)      
    //   return null;
    // }
  }
}
Base.extend(EB3000Service);

export default EB3000Service;
