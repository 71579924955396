import React from 'react'
import { Link } from 'react-router-dom'
import {Parser} from '@ses-education/content-parser'
// import Parser from '../../../shared-components/Parser'
import allQuestions from './questions.json';


const html = `<p>&nbsp;</p>
<div class="question quiz" data-question-id="795" data-question-type="quiz" data-quiz-title="preparation questions" data-updated="1617622228768">&nbsp;</div>
<p>&nbsp;</p>`;

const questions = {
    "795": {
        "value": null,
        "errorText": null
    }
}



class TestParser extends React.Component{

    render(){
      console.debug("test/parser props", this.props)
        return(<>
        <Link to="/test">Back to Test</Link>
        <h2>Testing Parser</h2>
            <Parser                
                {...{
                  html,
                  allQuestions,
                  questions
                }}
            />

            {
              /*
              html={currentHtml}
              onQuestionChange={this.onQuestionChange.bind(this)}
              questions={pageAnswers}
              allQuestions={filtered}        
              onErrors={this.onParserErrors}
              */
            }
        </>)
    }
}

export default TestParser