import { notificationsActionTypes } from '@ses-education/courses-components';
// const notificationsActionTypes = {}
console.debug("notificationsActionTypes", notificationsActionTypes);
const ActionTypes = {
  ...notificationsActionTypes,

  SHOW_CONTACT: 'SHOW_CONTACT',
  HIDE_CONTACT: 'HIDE_CONTACT',
  SEND_CONTACT: 'SEND_MESSAGE',
  RESET_CONTACT: 'RESET_MESSAGE',

  SHOW_MODAL: 'SHOW_MODAL',
  CLOSE_MODAL: 'CLOSE_MODAL',

  LOGIN: 'LOGIN',
  LOGOUT: 'LOGOUT',

  SET_USAGE_TIMESTAMP: 'USETIME',
  RENEW_TOKEN: 'RENEWTOKEN',

  SET_LANG: "SET_LANG",

  SET_CURRENT_PROGRAM_COURSE: "SET_CURRENT_PROGRAM_COURSE",

  // TESTER-SPECIFIC
  TOGGLE_NO_HARDWARE: "TOGGLE_NO_HARDWARE"
};

export default ActionTypes;
