import ActionTypes from './actionTypes';

export const toggleNoHardware = () => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.TOGGLE_NO_HARDWARE
    });
  };
};


export const setLanguage = (payload) => {
  return (dispatch) => {
    console.debug("dispathcing setLanguage with", payload)
    dispatch({
      type: ActionTypes.SET_LANG,
      payload
    });
  };
};