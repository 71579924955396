import React, { useState } from "react";
import { BrowserRouter, Link, Route } from "react-router-dom";
import { Routes as Switch } from "react-router";
// import RouteComponent from './RouteComponent';
import "./style.scss";
// import Notification from '../template/Notification';
import routes from "../../data/routes";
import Navbar from "../template/Navbar";
import { connect } from "react-redux";
import { 
  // Menu as MenuIcon, 
  Person as UserIcon } from "@material-ui/icons";
import { Container, Menu, MenuItem } from "@material-ui/core";
import { showMessage } from "../../redux/notificationActions";
import AuthService from "../../services/AuthService";
import defaultUniversityLogo from "./images/default-university-logo.png";
import RouteScrollingComponent from "./RouteScrollingComponent";
import LanguageSwitch from "./language-switch";
import WithTranslations from "../WithTranslations";

const {
  REACT_APP_MEDIA_URL = 'https://images.ses-education.com/courses',
  REACT_APP_BASE_URL
} = process.env;

const makeImageUrl = (image) => `${REACT_APP_MEDIA_URL}/organizations/${image}`;

// pass user, onShowMessage
const RightComponent = (props) => {
  const { user, onShowMessage, _t } = props;
  const { name = ""} = user;
  const [menuTarget, setMenuTarget] = useState(null);

  const show = (ev) => {
    setMenuTarget(ev.currentTarget);
  };

  const hide = (ev) => {
    setMenuTarget(null);
  };

  const logout = async (ev) => {
    setMenuTarget(null);
    const result = await AuthService.logout();

    if (!result) {
      onShowMessage(_t(`Failed to logout`), 'error');
    }
  };

  return (
    <div className='top-right'>
      <LanguageSwitch/>
      <div className='user-icon link' onClick={show}>
        <UserIcon />
        {name}
      </div>
      <Menu
        id='simple-menu'
        anchorEl={menuTarget}
        keepMounted
        open={Boolean(menuTarget)}
        onClose={hide}
      >
        <MenuItem component={Link} to={'/profile'} onClick={hide}>
          {_t("Profile")}
        </MenuItem>
        <MenuItem onClick={logout}>{_t("Logout")}</MenuItem>
      </Menu>
    </div>
  );
};

// pass homeLink hasLogoImage university
const LeftComponent = (props) => {
  const {homeLink = "/", hasLogoImage, university} = props;
  return (
    <div className="top-left">
      <Link to={homeLink}>
        <img
          src={
            hasLogoImage
              ? makeImageUrl(university.organization_logo_images.logo_small)
              : defaultUniversityLogo
          }
          alt={university.organization_title}
        />
        {!hasLogoImage && <span>{university.organization_title}</span>}
      </Link>
    </div>
  )
};
class AuthorizedMain extends React.Component {

  componentDidMount() {
    this.props.fetchTranslations([
      "Profile",
      "Logout",
    ]);
  }
  render() {
    const { user, onShowMessage, homeLink = "/", _t } = this.props;
    const { organizations } = user;
    const [university] = organizations || [];
    const hasLogoImage =
      university.organization_logo_images &&
      university.organization_logo_images.logo_small;
    const navbarProps = {
      // location,
      LeftComponent: <LeftComponent {...{homeLink, hasLogoImage, university }} />,
      RightComponent: <RightComponent {...{ user, onShowMessage, _t }} />,
      ContainerComponent: Container,
    };

    return (
      <BrowserRouter basename={REACT_APP_BASE_URL}>
        <Navbar {...navbarProps} />

        <div className={`main`}>
          <Switch>
            {routes.map((route, ind) => {            
              const { component, ...rest } = route;
              return (
                <Route
                  {...{
                    ...rest,
                    key: `route-${ind}`,           
                    element: <RouteScrollingComponent {...{ component, authorize:true }} />,
                  }}
                />
              );
            })}
          </Switch>
          {/* <Notification /> */}
        </div>
      </BrowserRouter>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onShowMessage: (message, type) => dispatch(showMessage(message, type)),
  };
};

export default WithTranslations (connect(null, mapDispatchToProps)(AuthorizedMain));
