import { createTheme } from "@material-ui/core";

const theme1 = createTheme({
  palette: {
    primary: {
      main: "#00479b",
    },
    secondary: {
      main: "#ff0048",
    },
  },
  typography: {
    fontFamily: ["Noto Sans", "sans-serif"].join(", "),
  },
});

export default theme1;
