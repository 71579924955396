import React from 'react'
import Error from '../Error'

class Page404 extends React.Component{

    render(){
        const {
            title = "Error 404",
            error = "Page not found"
        } = this.props;
        
        return <Error {...{title, error}} />
    }
}


export default Page404