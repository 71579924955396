import React from "react";
import ProgramService from "../../services/program";
import {
  // Card,
  // CardActionArea,
  // CardContent,
  Container,
  // Grid,
  Typography,
  // Breadcrumbs,
} from "@material-ui/core";
import Spinner from "../template/spinner";
import WithTranslations from "../WithTranslations";
import ProgramList from "../../shared-components/template/CustomCardList";
import ColumnedList from "../../shared-components/template/CustomCardList/columned-list";
import OrganizationsService from "../../services/organizations";
import { Navigate as Redirect } from "react-router-dom";
import Error from "../template/Error";
import { buildLink } from "../../utils/functions";

class Programs extends React.Component {
  state = {
    programs: null,
    firstOrganizationLink: null,
    error: null,
    userHasOrganizationSlug: null,
  };

  componentDidMount() {
    // fetch interface translations
    this.props.fetchTranslations(["My Programs", "Unknown error"]);
    this.fetchPrograms();
    this.fetchOrganizationData();
  }

  componentDidUpdate(prevProps) {
    // if organization slug was updated, update organization data
    const { organization } = this.props.match ? this.props.match.params : {};
    const { organization: prev_organization } = prevProps.match
      ? prevProps.match.params
      : {};

    const { _language } = this.props;
    const { _language: old_language } = prevProps;

    if (organization !== prev_organization) this.fetchOrganizationData();

    if (_language !== old_language) this.fetchPrograms();
  }

  /**
   * Build first organization link in case we need to redirect there.
   * check if user has current organization slug
   */
  async fetchOrganizationData() {
    console.debug("fetchOrganizationData props.match", this.props);
    const { organization } = this.props.match ? this.props.match.params : {};

    // build first organization link
    const firstOrganizationLink =
      await OrganizationsService.getFirstOrganizationLink();

    // check if user has current url slug in their organizations
    const userHasOrganizationSlug =
      await OrganizationsService.userHasOrganizationSlug(organization);

    // store error, if any
    const { error } = OrganizationsService;

    this.setState({ firstOrganizationLink, userHasOrganizationSlug, error });
  }

  async fetchPrograms() {
    const { _t } = this.props;
    const { organization } = this.props.match ? this.props.match.params : {};

    let programs = await ProgramService.getPrograms();

    // add course lists (and check result for validity)
    programs = Array.isArray(programs)
      ? programs.map((p) => ({
          ...p,
          link: buildLink({ organization, program: p.program_id }),
          description: (
            <>
              <p>{p.description}</p>
              {p.courses && p.courses[0] && <ColumnedList items={p.courses} />}
            </>
          ),
        }))
      : false;

    this.setState({
      programs,
      error: Array.isArray(programs)
        ? null
        : ProgramService.error || _t("Unknown error"),
    });
  }

  render() {
    // console.log('Programs: props', this.props);

    // check whether translations are loaded and get _t function
    const { _t, translationsLoaded } = this.props;

    const { organization } = this.props.match ? this.props.match.params : {};

    const { firstOrganizationLink, userHasOrganizationSlug, error } =
      this.state;

    let { programs } = this.state;
    console.debug("Rendering programs:", programs);

    if (programs === false) return <Error {...{ error }} />;

    if (!translationsLoaded || firstOrganizationLink === null)
      return <Spinner />;

    // if firstOrganizationLink failed - show error
    if (firstOrganizationLink === false)
      return <Error error={error || _t("Unknown error")} />;

    // if organization doesn't match any user's organization - redirect to first organization in his list
    console.debug(
      "firstOrganizationLink, userHasOrganizationSlug",
      firstOrganizationLink,
      userHasOrganizationSlug
    );
    if (!userHasOrganizationSlug)
      return <Redirect to={firstOrganizationLink} />;
    // return <Error error="test" />

    return (
      <Container>
        {/* <Breadcrumbs className='breadcrumbs'>
          <Typography color='inherit'>{`${organization}`}</Typography>
        </Breadcrumbs> */}
        <Typography variant="h1" color="primary" gutterBottom>
          {_t("My Programs")}
        </Typography>
        {programs === null ? (
          <Spinner />
        ) : programs === false ? (
          <Typography>
            {error || _t("Unknown error")}
          </Typography>
        ) : (
          <ProgramList
            items={programs.map((p) => ({
              ...p,
              image:
                Array.isArray(p.images) && p.images[0] ? p.images[0] : null,
            }))}
            itemProps={{ md: 12, lg: 12 }}
            {...{ organization }}
          />
        )}
      </Container>
    );
  }
}

export default WithTranslations(Programs);
