import store from '../redux/store';
import Base from './baseClientService';

const defaultPages = {
  objectives: { questions: null, status: 'open' },
  discussion: { questions: null, status: 'closed' },
  prep_question: { questions: null, status: 'closed' },
  experiment: { questions: null, status: 'closed' },
  summary_question: { questions: null, status: 'closed' },
};

class CourseService extends Base {
  static async getUserCourses() {
    try {
      const items = await this.GET('/student/courses');
      return items;
    } catch (e) {
      console.error('Error fetching user courses: ', e.message);
      return false;
    }
  }

  static async getCourse(id) {
    try {
      const result = await this.GET('/student/courses/course/' + id);
      return result;
    } catch (e) {
      console.error('Error fetching user course: ', e.message);
      return this.Error(e.message);
    }
  }

  static async getCourseExperiments(id) {
    const language = store.getState().root.language;
    const index = CourseService.getExperimentCacheIndex(id, language );

    const getFromServer = async (ind) => {
      try {
        const result = await this.GET(
          `/student/courses/course/${id}/experiments`
        );

        // this.cache(index, result, callback);
        this.cache(ind, result);
        return result;
      } catch (e) {
        console.error('Error fetching course experiment: ', e.message);
        this.Error(e.message);
        return false;
      }
    };

    return await this.getCachedOr(index, getFromServer);
  }

  static async getCourseExperimentsList(id) {
    const language = store.getState().root.language;
    console.debug("getCourseExperimentsList language ", language)
    const index = `course-experiments-list-${id}-${language}`;

    const getFromServer = async () => {
      try {
        const result = await this.GET(
          `/student/courses/course/${id}/experiments-list`
        );
        // this.cache(index, result, callback);
        this.cache(index, result);
        return result;
      } catch (e) {
        console.debug( "Error object:", e );
        console.error('Error fetching course experiment: ', e.message);
        return this.Error(e.message || "Error fetching course experiment data");        
      }
    };

    return await this.getCachedOr(index, getFromServer);
  }

  static getExperimentCacheIndex(id, language = "en") {
    return `course_experiments_${id}-${language}`;
  }

  static getPageCacheIndex(exp_id, chapter) {
    return `page_${exp_id}_${chapter}`;
  }

  static async updatePageStatus(course_id, exp_id, chapter, status) {
    try {
      await this.POST(
        `/student/courses/course/${course_id}/update-page/${exp_id}/${chapter}`,
        { status }
      );
      return true;
    } catch (e) {
      console.error('Error updating page status: ', e.message);
      this.Error(e.message);
      return false;
    }
  }
}
Base.extend(CourseService);

export default CourseService;
