import React from 'react'


class Guidance extends React.Component{

    render(){
        return("Guidance component")
    }
}

export default Guidance