import "./course.scss";
import React from "react";
import ExperimentList from "./experiment-list";
import ExperimentContent from "./experiment-content";
import pageMap from "./../../../../../data/pages.json";
import ProgressService from "../../../../../services/progress";
import CourseService from "../../../../../services/course";
import { buildLink, scrollToHash } from "../../../../../utils/functions";
import { showModal } from "../../../../../redux/modalActions";
import { Grid } from "@material-ui/core";
import { Navigate as Redirect } from "react-router-dom";
import Spinner from "../../../../template/spinner";
import Error from "../../../../template/Error";
import { showMessage } from "../../../../../redux/notificationActions";
import { connect } from "react-redux";
import WithTranslations from "../../../../WithTranslations";
class Course extends React.Component {
  state = {
    courseProgress: null,
    experiments: null,
    currentExperiment: null,
    redirect: null,
    error: null,
    experimentError: null,
    courseError: null,
    student_experiment_id: null,
    experimentProgress: null,
    next_chapter_link: null,
    prev_chapter_link: null,
  };
  componentDidMount() {
    this.props.fetchTranslations(["Attention!"]);
    this.fetchData();
  }
  componentDidUpdate(prevProps) {
    if (
      this.props.course !== prevProps.course ||
      this.props.exp_id !== prevProps.exp_id ||
      this.props.chapter !== prevProps.chapter
    ) {
      this.fetchData();
    }

    // if chapter or experiment is changed within the same course - scroll to the top
    if (
      this.props.course === prevProps.course &&
      (this.props.exp_id !== prevProps.exp_id ||
        this.props.chapter !== prevProps.chapter)
    ) {
      // scroll to the course main container with 90 pixels offset to show the course tabs
      scrollToHash("course-main", -90, "smooth");
    }

    if (this.state.redirect) {
      this.setState({ redirect: null });
    }
  }

  prepareLinks = () => {
    const { exp_id, chapter, course, prog_id, organization } = this.props;
    const { course_id } = course;
    const { experiments } = this.state;
    const currentChapterIndex =
      chapter && pageMap && pageMap.findIndex((page) => page === chapter);
    // || -1;
    const currentExperimentIndex =
      Array.isArray(experiments) &&
      experiments.findIndex(
        (exp) => exp.course_experiment_id === parseInt(exp_id)
      );
    // || -1;
    let next_chapter_link = null;
    let prev_chapter_link = null;
    console.log(
      "preperLinks",
      currentChapterIndex,
      currentExperimentIndex,
      exp_id,
      experiments,
      pageMap
    );
    if (
      pageMap &&
      currentChapterIndex > 0 &&
      currentChapterIndex < pageMap.length
    ) {
      prev_chapter_link = buildLink({
        organization,
        program: prog_id,
        course: course_id,
        slug: "experiment",
        experiment: exp_id,
        chapter: pageMap[currentChapterIndex - 1],
      });
    }
    if (
      pageMap &&
      currentChapterIndex > -1 &&
      currentChapterIndex < pageMap.length - 1
    ) {
      next_chapter_link = buildLink({
        organization,
        program: prog_id,
        course: course_id,
        slug: "experiment",
        experiment: exp_id,
        chapter: pageMap[currentChapterIndex + 1],
      });
    }
    if (
      pageMap &&
      experiments &&
      currentChapterIndex > -1 &&
      currentChapterIndex === pageMap.length - 1 &&
      currentExperimentIndex > -1 &&
      currentExperimentIndex < experiments.length - 1
    ) {
      next_chapter_link = buildLink({
        organization,
        program: prog_id,
        course: course_id,
        slug: "experiment",
        experiment:
          Array.isArray(experiments) &&
          experiments[currentExperimentIndex + 1].course_experiment_id,
        chapter: pageMap[0],
      });
    }
    if (
      pageMap &&
      experiments &&
      currentChapterIndex > -1 &&
      currentChapterIndex === pageMap.length - 1 &&
      currentExperimentIndex > -1 &&
      currentExperimentIndex === experiments.length - 1
    ) {
      next_chapter_link = buildLink({
        organization,
        program: prog_id,
        course: course_id,
        slug: "progress",
      });
    }
    this.setState({ next_chapter_link, prev_chapter_link });
  };
  // fetchExperimentData = async () => {
  //   const { exp_id } = this.props;
  //   if(!exp_id) return;
  //   const experimentProgress = await ProgressService.getExperimentProgress(exp_id);
  //   if(!experimentProgress){
  //     const error = ProgressService.error || 'Unknown error';
  //     this.setState({experimentError: error, courseError: error, experimentProgress});
  //     return;
  //   }
  //   console.log("experimentProgress", experimentProgress);
  //   this.setState({experimentProgress, experimentError: null, courseError: null},this.preperLinks);
  // }
  fetchData = async () => {
    console.debug("fetchData called");
    const { exp_id, course } = this.props;
    const { course_id } = course;
    if (!course_id) return;
    const experiments = await CourseService.getCourseExperiments(course_id);
    const courseProgress = await ProgressService.getCourseProgress(course_id);
    if (!Array.isArray(experiments)) {
      this.setState({
        experimentError: CourseService.error || "Unknown error",
        experiments,
      });
      return;
    }
    if (!courseProgress) {
      this.setState({
        courseError: ProgressService.error || "Unknown error",
        courseProgress,
      });
      return;
    }

    let { current_experiment_id: student_experiment_id } = courseProgress;

    // currently selected (via URL) experiment within course experiments array, if any
    const currentExperiment =
      (Array.isArray(experiments) &&
        experiments.find((e) => e.course_experiment_id === parseInt(exp_id))) ||
      null;

    // if experiment is valid, get experiment progress
    const experimentProgress = currentExperiment
      ? await ProgressService.getExperimentProgress(exp_id)
      : null;

    // prepare redirect link.
    // there are two cases: experiment is selected/valid, or not selected/valid
    const experimentIndex =
      courseProgress &&
      Array.isArray(experiments) &&
      experiments.findIndex(
        (exp) =>
          exp.course_experiment_id ===
          parseInt(courseProgress.current_experiment_id)
      );
    const currentExperimentIndex =
      exp_id &&
      Array.isArray(experiments) &&
      experiments.findIndex(
        (exp) => exp.course_experiment_id === parseInt(exp_id)
      );
    console.log(
      "redirect",
      currentExperiment,
      currentExperimentIndex,
      experimentIndex
    );
    const redirect =
      currentExperiment &&
      currentExperimentIndex > -1 &&
      experimentIndex > -1 &&
      currentExperimentIndex <= experimentIndex
        ? await this.redirectWithValidExperiment(
            currentExperiment,
            experimentProgress
          )
        : await this.redirectWithInvalidExperiment(courseProgress, experiments);

    this.setState(
      {
        currentExperiment,
        courseProgress,
        experimentProgress,
        experiments,
        redirect,
        student_experiment_id,
        experimentError: null,
        courseError: null,
      },
      this.prepareLinks
    );
  };

  /**
   * Build redirect link if no experiment selected or selected experiment is invalid
   * @param {*} courseProgress
   */
  redirectWithInvalidExperiment = async (courseProgress, experiments) => {
    const { organization, prog_id, course } = this.props;
    const { course_id } = course;
    const { current_experiment_id } = courseProgress;
    console.debug("redirectWithInvalidExperiment called");

    // if course is finished, redirect to first chapter of first experiment
    if (courseProgress.status === "done") {
      console.debug(
        "redirectWithInvalidExperiment - course is finished, redirect to first chapter of first experiment"
      );
      return buildLink({
        organization,
        program: prog_id,
        course: course_id,
        slug: "experiment",
        experiment: experiments[0].course_experiment_id,
        chapter: pageMap[0],
      });
    }
    //  course not finished: redirect to current chapter of current experiment
    console.debug(
      "redirectWithInvalidExperiment - course not finished, redirect to current chapter of current experiment"
    );
    // get current experiment progress
    const currentExperimentProgress =
      await ProgressService.getExperimentProgress(current_experiment_id);
    // if there is no progress, redirect to first chapter of current experiment
    if (!currentExperimentProgress || !currentExperimentProgress.current_page) {
      return buildLink({
        organization,
        program: prog_id,
        course: course_id,
        slug: "experiment",
        experiment: current_experiment_id,
        chapter: pageMap[0],
      });
    }
    // there is progress, so redirect to current chapter of current experiment
    return buildLink({
      organization,
      program: prog_id,
      course: course_id,
      slug: "experiment",
      experiment: current_experiment_id,
      chapter: currentExperimentProgress.current_page,
    });
  };

  /**
   * Build redirect if the experiment currently selected via URL is valid
   * @param {*} currentExperiment
   * @returns
   */
  redirectWithValidExperiment = async (
    currentExperiment,
    experimentProgress
  ) => {
    console.debug("redirectWithValidExperiment");
    const { organization, prog_id, course, exp_id, chapter } = this.props;
    const { course_id } = course;

    // if experiment progress is not valid, redirect to first chapter of current experiment
    if (!experimentProgress || !experimentProgress.current_page) {
      return buildLink({
        organization,
        program: prog_id,
        course: course_id,
        slug: "experiment",
        experiment: exp_id,
        chapter: pageMap[0],
      });
    }

    // if chapter is selected and valid...
    if (chapter && pageMap.includes(chapter)) {
      // check if the selected chapter is larger than current chapter in experiment progress
      const currentChapterIndex = pageMap.findIndex((page) => page === chapter);
      const progressChapterIndex = pageMap.findIndex(
        (page) => page === experimentProgress.current_page
      );
      if (currentChapterIndex > progressChapterIndex) {
        // selected chapter is larger than current, redirect to the current chapter of the experiment
        return buildLink({
          organization,
          program: prog_id,
          course: course_id,
          slug: "experiment",
          experiment: exp_id,
          chapter: experimentProgress.current_page,
        });
      }

      // chapter is smaller than current, no need to redirect at all
      return null;
    }

    // the chapter is not selected or not valid:
    // check if the experiment is finished.
    if (currentExperiment.status === "done") {
      // the experiment is finished, redirect to the first chapter of the experiment

      return buildLink({
        organization,
        program: prog_id,
        course: course_id,
        slug: "experiment",
        experiment: exp_id,
        chapter: pageMap[0],
      });
    }

    // the experiment is NOT finished, redirect to the current chapter of the experiment
    return buildLink({
      organization,
      program: prog_id,
      course: course_id,
      slug: "experiment",
      experiment: exp_id,
      chapter: currentExperiment.current_page || pageMap[0], // fall back to first chapter, just in case
    });
  };
  resetPageProgress = async () => {
    const { onShowMessage, exp_id } = this.props;

    console.debug("resetting page progress for experiment", exp_id);

    const resetPageResult = await ProgressService.resetPageProgress(exp_id);
    if (!resetPageResult) {
      onShowMessage(ProgressService.error || "Unknown server error", "error");
      return;
    }

    // call flags handler callback
    this.flagsHandler(resetPageResult.flags);
  };

  autofinishPage = async () => {
    console.debug("autofinishing page");

    const { chapter, exp_id, onShowMessage } = this.props;
    const autofinishResult = await ProgressService.autofinishPage(
      exp_id,
      chapter
    );
    if (!autofinishResult) {
      onShowMessage(ProgressService.error, "error");
      return;
    }

    // extract flags
    // const { courseStatusChanged, experimentStatusChanged, pageStatusChanged } =
    //   flags;

    this.setState(
      {
        courseProgress: null,
        currentExperiment: null,
        next_chapter_link: null,
      },
      () =>
        // call flags handler callback
        this.flagsHandler(autofinishResult.flags)
    );

    // repopulate and rerender
    // await this.populateState();
  };
  onPageUpdate = async (answers, eb3000) => {
    // console.debug("onPageUpdate called with:", answers, eb3000);
    const { chapter, exp_id, onShowMessage, onShowModal } = this.props;
    try {
      const result = await ProgressService.updateAnswers(
        exp_id,
        chapter,
        answers,
        // all pages without questions DO NOT REQUIRE EB3000 connection, so FALSE here
        eb3000
      );

      console.debug("onPageUpdate result", result);
      if (!result) {
        onShowMessage(ProgressService.error, "error");
        return;
      }
      const { flags = {}, message, answers: serverAnswers } = result;

      if (message) {
        const { _t } = this.props;
        // auto-size class makes it shrink to content
        onShowModal(
          _t("Attention!"),
          message,
          [{ type: "ok", text: "close" }],
          {
            className: "auto-size",
          }
        );
      }
      this.flagsHandler(flags);
      //return answers to parser
      return serverAnswers;
    } catch (err) {
      console.debug(
        "Error inside onQuestionChange inside ExperimentContent",
        err
      );
      onShowMessage(
        err.message || "Unknown error, please try reloading the page",
        "error"
      );
    }
  };
  flagsHandler = async ({
    courseStatusChanged,
    experimentStatusChanged,
    pageStatusChanged,
  }) => {
    console.debug(
      "flagsHandler",
      courseStatusChanged,
      experimentStatusChanged,
      pageStatusChanged
    );

    if (experimentStatusChanged || courseStatusChanged || pageStatusChanged) {
      console.debug("flagsHandler: reloading state");
      await this.fetchData();
    }
  };
  render() {
    const {
      courseProgress,
      experiments,
      currentExperiment,
      redirect,
      student_experiment_id,
      experimentProgress,
      experimentError,
      courseError,
      next_chapter_link,
      prev_chapter_link,
    } = this.state;
    const {
      exp_id,
      chapter,
      course,
      prog_id,
      organization,
      fetchTranslations,
      _t,
      translationsLoaded,
    } = this.props;
    const { course_id } = course;
    if (redirect) {
      return <Redirect to={redirect} />;
    }
    return (
      <div className="course-main-container" id="course-main">
        <Grid container spacing={3}>
          <Grid item xs={12} md={3} className="course-accordion-block">
            {experiments && student_experiment_id && experimentProgress && (
              <ExperimentList
                {...{
                  experiments,
                  exp_id,
                  prog_id,
                  course_id,
                  organization,
                  chapter,
                  student_experiment_id,
                  experimentProgress,
                  fetchTranslations,
                  _t,
                  translationsLoaded,
                }}
                // this is the experiment-list.jsx component
              />
            )}
            {!(experiments && student_experiment_id && experimentProgress) &&
              !experimentError && <Spinner />}
            {!(experiments && student_experiment_id && experimentProgress) &&
              experimentError && <Error error={experimentError} />}
          </Grid>
          <Grid item xs={12} md={9}>
            <div className="exp-content-container">
              {experiments &&
                courseProgress &&
                currentExperiment &&
                experimentProgress &&
                next_chapter_link && (
                  <ExperimentContent
                    {...{
                      organization,
                      prog_id,
                      experiments,
                      courseProgress,
                      currentExperiment,
                      course,
                      chapter,
                      experimentProgress,
                      next_chapter_link,
                      prev_chapter_link,
                      fetchTranslations,
                      _t,
                    }}
                    // this is the experiment-content.jsx component
                    resetPageProgress={this.resetPageProgress}
                    autofinishPage={this.autofinishPage}
                    onPageUpdate={this.onPageUpdate}
                  />
                )}
              {!(
                next_chapter_link &&
                courseProgress &&
                currentExperiment &&
                experimentProgress
              ) &&
                !courseError && <Spinner />}
              {!(
                next_chapter_link &&
                courseProgress &&
                currentExperiment &&
                experimentProgress
              ) &&
                courseError && <Error error={courseError} />}
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  onShowMessage: (message, type) => dispatch(showMessage(message, type)),
  onShowModal: (header, text, buttons, modalProps) =>
    dispatch(showModal(header, text, buttons, modalProps)),
});
export default WithTranslations(connect(null, mapDispatchToProps)(Course));
