import React, { useEffect } from "react";
import {
  Card,
  CardActionArea,
  CardContent,
  Container,
  Grid,
  Typography,
  Button,
  // Breadcrumbs,
} from "@material-ui/core";
import Spinner from "../template/spinner";
import ProgramService from "../../services/program";
import CourseService from "../../services/course";
import { Link, Navigate as Redirect } from "react-router-dom";
import Error from "../template/Error";
import "./courses-list.scss";
import { buildLink } from "../../utils/functions";
import {
  Check,
  Home,
  ErrorRounded,
  Block,
  Print,
  Lock,
} from "@material-ui/icons";
import {AutoBreadcrumbs} from "@ses-education/courses-components";
import WithTranslations from "../WithTranslations";
import { connect } from "react-redux";
import { showModal } from "../../redux/modalActions";
import { strFormat } from "../../utils/functions";
import store from "../../redux/store";
const defaultItemProps = {
  xs: 12,
  md: 4,
  lg: 3,
};

const ExperimentList = ({ experiments }) => (
  <ul className="course-experiments-list">
    {Array.isArray(experiments) &&
      experiments.map((e, ind) => <li key={`exp-name-${ind}`}>{e.title}</li>)}
  </ul>
);

const defaultImage = `${process.env.PUBLIC_URL}/images/course-no-image.png`;

// TODO:
// export this List as stand-alone component and use it in
// components/course-list and components/programs
const CourseList = (props) => {
  let {
    items,
    spacing = 4,
    itemProps,
    program_id,
    organization,
    onShowModal,
    _t,
  } = props;
  itemProps = { ...defaultItemProps, ...itemProps };
  useEffect(() => {
    // const finduserfunc = (item) =>
    //   item.user_id === store.getState().root.user.id;
    // const finduser = store
    //   .getState()
    //   .courseNavigation.user_course_navigation.find(finduserfunc) || {
    //   programs: [],
    // };
    // const findprogramfunc = (item) => item.program_id === program_id;
    // const program = finduser.programs.find(findprogramfunc);
    // const ind = (program || {}).current_course;
    const ind = (items.find((item) =>{
      const progress = parseInt(item.progress_percent || 0);
      const isDone = progress === 100;
      return !isDone;
    })||{}).course_id;
    if (document.getElementById(`course-${ind}`))
      document
        .getElementById(`course-${ind}`)
        .scrollIntoView({ behavior: "smooth" });
  }, []);

  return (
    <Grid container spacing={spacing} className="courses-list card-list">
      {items.map((item, ind) => {
        const progress = parseInt(item.progress_percent || 0);
        const score = parseInt(item.total_score || 0);
        const isDone = progress === 100;
        const Failed = isDone && score < 75; // TODO: get from config?
        const requirementsNotMet =
          Array.isArray(item.required_courses) &&
          item.required_courses.length > 0;
        const requirementText = requirementsNotMet
          ? strFormat(_t("coursesRequired"), {
              "%courses%": `<ul>
          ${item.required_courses.map((c) => `<li>${c.title}</li>`).join("")} 
          </ul>`,
            })
          : null;

        return (
          <Grid
            item
            {...itemProps}
            id={`course-${item.course_id}`}
            key={`course-${ind}`}
            className="card-container scrolling-margin"
          >
            {isDone && (
              <div className="stamp done">
                <Check /> {_t("Done!")}
              </div>
            )}
            {Failed && (
              <div className="stamp failed">
                <Block /> {_t("Failed")}
              </div>
            )}
            {requirementsNotMet && (
              <div className="stamp blocked">
                <Lock />
              </div>
            )}
            <Card
              className={`course-card ${
                isDone ? "done" : Failed ? "failed" : ""
              }${requirementsNotMet ? " blocked" : ""}`}
              // style={{
              //   backgroundColor: 'rgb(217, 217, 217)',
              // }}
            >
              <CardActionArea
                component={
                  requirementsNotMet
                    ? ({ children, ...other }) => (
                        <div {...other}>{children}</div>
                      )
                    : Link
                }
                to={
                  requirementsNotMet
                    ? null
                    : buildLink({
                        organization,
                        program: program_id,
                        course: item.course_id,
                      })
                }
                onClick={
                  requirementsNotMet
                    ? () => {
                        console.debug(
                          "clicked on call onShowModal with ",
                          requirementText
                        );
                        onShowModal(
                          _t("coursesRequiredHeader"),
                          requirementText,
                          [{ text: "Ok" }],
                          { className: "auto-size" }
                        );
                      }
                    : () => console.debug("clicked on link")
                }
              >
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={3}
                    className="image-display"
                    style={{
                      backgroundImage: `url(${item.image_url || defaultImage})`,
                      backgroundPosition: "center center",
                      backgroundSize: "cover",
                    }}
                    // component='img'
                    // src={
                    //   item.image
                    //     ? item.image
                    //     : 'https://images.ses-education.com/courses/course-no-image.png'
                    // }
                    // style={{ height: 'auto', width: 200 }}
                  />

                  <Grid item xs={12} sm={6} md={6} lg={7}>
                    <CardContent component="h3">
                      {item.course_code} - {item.title}
                    </CardContent>
                    <CardContent style={{ padding: 0 }}>
                      {/* {generateList(item.course_id)} */}
                      <ExperimentList experiments={item.experiments} />
                    </CardContent>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={2}
                    lg={2}
                    className="flex align-items-stretch justify-content-stretch"
                  >
                    <div className="flex column align-items-center justify-content-center grow-1">
                      {progress < 100 && (
                        <>
                          <span className="text-label">{_t("Progress")}</span>
                          <span className="text-value">{progress}%</span>
                        </>
                      )}
                      {item.progress_percent === 100 && (
                        <>
                          <span className="text-label">
                            {_t("Your score is")}
                          </span>
                          <span className="text-value">
                            {score} {_t("out of 100")}
                          </span>
                        </>
                      )}
                    </div>
                  </Grid>
                </Grid>
              </CardActionArea>
            </Card>
          </Grid>
        );
      })}
    </Grid>
  );
};

class Courses extends React.Component {
  state = {
    currentCourses: null,
    programs: null,
    error: null,
    experimentNames: {},
  };

  async componentDidMount() {
    console.log("this.props: ", this.props);
    this.fetchPrograms();
    this.props.fetchTranslations([
      "Done!",
      "Failed",
      "Closed",
      "Progress",
      "Your score is",
      "out of 100",
      "Error fetching data from server. Please try reloading page.",
      "Courses of program %programTitle%",
      "coursesRequired",
      "coursesRequiredHeader",
    ]);
  }

  async componentDidUpdate(prevProps) {
    console.debug("courses-list componentDidUpdate", prevProps, this.props);
    if (prevProps._language !== this.props._language) this.fetchPrograms();
  }

  extractCourses = async () => {
    const { programs } = this.state;
    if (!Array.isArray(programs)) {
      this.setState({
        error:
          "There was an error fetching programs data. Please, try reloading the page.",
      });
      return;
    }

    //const allCourses = await CourseService.getUserCourses();

    // get the id of the current program
    const program_id = this.props.match.params.prog_id;
    // get current program from all user programs
    const currentProgram = programs.find(
      (p) => p.program_id === parseInt(program_id)
    );

    // get list of all courses attached to current program
    let currentCourses = currentProgram ? currentProgram.courses : [];

    // add experiment list to each course
    currentCourses = await this.extractExperiments(currentCourses);
    console.debug("extractCourses currentCourses:", currentCourses);
    // this.setState({ currentCourses }, () =>
    //   this.extractExperiments(currentCourses)
    // );
    this.setState({ currentCourses });
  };

  async fetchPrograms() {
    const programs = await ProgramService.getPrograms();
    this.setState({ programs }, () => this.extractCourses());
  }

  async extractExperiments(currentCourses) {
    if (!Array.isArray(currentCourses)) return currentCourses;

    // create array of ids of all courses present in currentProgram
    // let currentCoursesIds = [];
    // currentCourses.forEach((course) => {
    //   currentCoursesIds.push(course.course_id);
    // });
    // let currentCoursesIds = currentCourses.map( course => course.course_id );

    // extract the names of experiments in every course
    let experimentNames = { ...this.state.experimentNames };

    // for (const id of currentCoursesIds) {
    //   // const result = await CourseService.getCourseExperiments(id);
    //   const result = await CourseService.getCourseExperiments(id);
    //   experimentNames[id] = result.map((exp) => exp.title);
    // }
    for (let i = 0; i < currentCourses.length; i++) {
      // store within currentCourses
      currentCourses[i].experiments =
        await CourseService.getCourseExperimentsList(
          currentCourses[i].course_id
        );
    }

    // save names to state
    this.setState({ experimentNames });
    //console.log('extractExperiments - currentCoursesIds: ', currentCoursesIds);
    console.log("extractExperiments - experimentNames: ", experimentNames);

    return currentCourses;
  }

  render() {
    // link to program list (pass it from parent component if needed)
    const { rootLink = "/", onShowModal } = this.props;

    const { currentCourses, programs, error, experimentNames } = this.state;
    // const program_id = this.props.match.params.prog_id;
    const { organization, prog_id: program_id } = this.props.match
      ? this.props.match.params
      : {};

    // wait for programs list to load
    // if ( programs === false || !program_id || !organization ) return error ? <Error {...{error}} /> : <Spinner />;
    if (!programs) return error ? <Error {...{ error }} /> : <Spinner />;

    const programIndex = programs
      ? programs.findIndex(
          (p) => parseInt(p.program_id) === parseInt(program_id)
        )
      : false;

    if (programIndex < 0) return <Redirect to={rootLink} />;

    const programTitle = programs[programIndex].title;

    // Build breadcrumbs
    let breadcrumbs = [
      { to: buildLink({ organization }), icon: <Home /> },
      {
        to: buildLink({ organization, program: program_id }),
        text: programTitle,
      },
    ];
    return (
      <Container>
        <AutoBreadcrumbs items={breadcrumbs} />

        {/* <Breadcrumbs className='breadcrumbs'>
          <Link to={`/${organization}`}>{`${organization}`}</Link>
          <Typography color='inherit'>{`${program_id}`}</Typography>
        </Breadcrumbs> */}
        <Typography
          className="flex row justify-content-space-between"
          variant="h1"
          color="primary"
          gutterBottom
        >
          <span>
            {strFormat(this.props._t("Courses of program %programTitle%"), {
              "%programTitle%": programTitle,
            })}
          </span>
          <Button color={"primary"} onClick={() => window.print()}>
            <Print />
          </Button>
        </Typography>
        {currentCourses === null ||
        programs === null ||
        experimentNames === {} ? (
          <Spinner />
        ) : currentCourses === false || programs === false ? (
          <Typography>
            {this.props._t(
              "Error fetching data from server. Please try reloading page."
            )}
          </Typography>
        ) : (
          <CourseList
            program_id={this.props.match.params.prog_id}
            items={currentCourses}
            // names={experimentNames}
            itemProps={{ md: 12, lg: 12 }}
            {...{ organization, onShowModal }}
            {...this.props}
          />
        )}
      </Container>
    );
  }
}

const dispatchToProps = (dispatch) => ({
  onShowModal: (header, text, buttons, modalProps) =>
    dispatch(showModal(header, text, buttons, modalProps)),
});

export default WithTranslations(connect(null, dispatchToProps)(Courses));
