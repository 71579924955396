import Login from '../components/AuthComponent/login';
import Page404 from '../components/template/Page404';
import About from '../components/AboutUs';
import ResetPassword from '../components/AuthComponent/reset-password';
import { Navigate as Redirect } from 'react-router-dom';

const components = {
  // Organizations,
  // Programs,
  // Courses,
  // Course,
  ResetPassword,
  About,
  Login,
  Page404,
};

export const routes = [
  {
    exact: true,
    path: '/',
    component: components['Login'],
  },
  {
    exact: true,
    path: '/reset-password',
    component: components['ResetPassword'],
  },
  {
    exact: true,
    path: '/reset-password/:hash',
    component: components['ResetPassword'],
  },
  {
    exact: true,
    path: '/about',
    component: components['About'],
  },
  { exact: false, path: '*', component: () => <Redirect to={'/'} /> },
];

export default routes;
