import React from 'react'
import {Typography, Container} from '@material-ui/core'
import {AutoBreadcrumbs} from '@ses-education/courses-components';


const cleanupHtml = html => {

  // Sometimes a GET request returns a complete HTML page, let's extract error content from it, if any.
  // Check if there's an html body in html (it's a complete HTML page) and if there is - return its contents
  const bodyMatches = /<body[^>]*>(.*?)<\/body>/is.exec(html);

  // it should produce an array with found group at 0 and group content at 1, let's return [1].
  if( Array.isArray(bodyMatches) && bodyMatches[1]) return bodyMatches[1]

  return html;
}

/**
 * 
 * @param {*} props 
 * Props are:
 * title - to show in title portion
 * error - html or text to show in text portion
 * @returns 
 */
const Error = props => <Container>
  {props.breadcrumbsItems && <AutoBreadcrumbs items={props.breadcrumbsItems} /> }
  <Typography  color="secondary" variant={props.headerVariant || "h2"}>{props.title || "Error"}</Typography>
  <Typography  dangerouslySetInnerHTML={{__html: cleanupHtml( props.error || "Error!" ) }}></Typography>
</Container>


Error.cleanupHtml = cleanupHtml;

export default Error;
