import { Container, Typography, Button } from '@material-ui/core';
import {Link} from 'react-router-dom';
import React from 'react';
import WithTranslations from '../WithTranslations';
import { connect } from 'react-redux';
import { Home } from '@material-ui/icons';
import {AutoBreadcrumbs} from '@ses-education/courses-components';

class AboutUs extends React.Component {
  async componentDidMount() {
    this.props.fetchTranslations(['About Us']);
  }
  render() {
    
    const {_t, translationsLoaded, translationError, user } = this.props
    let breadcrumbs = [
      { to: "/", icon: <Home /> },
      { text: _t("About Us") },
    ];
    
    return <Container className="about">
      <AutoBreadcrumbs items={breadcrumbs} />
    <Typography color="primary" variant="h1"> {_t("About Us")}</Typography>
        <div className="skeleton text"></div>
        
        {
            // show Login button only if user is not set
        !user && 
        <div className="buttons"><Button component={Link} to="/" variant="contained" color="primary">{_t("Login")}</Button></div>
        }
    </Container> 
  }
}

const stateToProps = state => ({
  user: state.root.user
})

export default WithTranslations( connect( stateToProps )( AboutUs  ) )
