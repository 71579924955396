import A from './actionTypes';
import Actions from './actions';
const localStorageKey = 'SEScoursesApp';

let localStorageState;

try {
  localStorageState = localStorage.getItem(localStorageKey)
    ? JSON.parse(localStorage.getItem(localStorageKey))
    : {};
} catch (e) {
  localStorageState = {};
}

const initialState = {
  ...{
    user: null,
    authorization: null,
    language: "en",
    // messages: [],
    // showModal: false,
    // modalContents: {},
    // message: null, // {message: String, type: String (danger|warning|success|info)}
    lastUsed: null,
    tokenTimestamp: Date.now(),
    allowNoHardwareExperiment: false
  },
  // overwrite values from local state, if any
  ...localStorageState,
};

export default function root(state = initialState, action) {
  let newState = { ...state };
  let { messages } = state;
  let now = Date.now();

  const {payload, type} = action;

  switch (action.type) {
    case A.SHOW_MESSAGE:
      if (Array.isArray(messages)) {
        // todo: validate payload type
        messages = [...messages, action.payload];
        newState = { ...state, messages };
      }
      break;
    case A.DELETE_MESSAGE:
      if (Array.isArray(messages) && messages.length > 0) {
        messages = [...messages.splice(0, messages.length - 1)];
        newState = { ...state, messages };
      }
      break;
    case A.SHOW_MODAL:
      newState = { ...state, showModal: true, modalContents: action.payload };
      break;
    case A.CLOSE_MODAL:
      newState = { ...state, showModal: false, modalContents: {} };
      break;
    
    case A.TOGGLE_NO_HARDWARE:
      // toggle "no hardware" mode for tester, that allows experiment without EB3000 connection
      newState = { ...state, allowNoHardwareExperiment: !state.allowNoHardwareExperiment };
      break;

    case A.SET_LANG:      
      console.debug("Redux: set language to", payload)
      newState = { ...newState, language: payload };
      break;

    case A.LOGIN:
      const { token, user } = action.payload;
      // put token into state as authorization header value
      newState = {
        ...state,
        language: user.interface_language,
        user,
        authorization: `Bearer ${token}`,
        tokenTimestamp: now,
      };
      Actions.onTokenUpdate(now);
      break;

    case A.LOGOUT:
      newState = { ...state, authorization: null, user: null };
      Actions.clearTokenUpdateTimer();
      break;

    case A.RENEW_TOKEN:
      newState = {
        ...state,
        authorization: `Bearer ${action.payload}`,
        tokenTimestamp: now,
      };
      Actions.onTokenUpdate(now);
      break;

    case A.SET_USAGE_TIMESTAMP:
      console.debug("setting usage timestamp to", now)
      try {
        newState = { ...state, lastUsed: now };
      } catch (e) {
        console.error(e.message);
      }
      break;

    default:
    //newState = state;
  }

  localStorage.setItem(localStorageKey, JSON.stringify(newState));

  return newState;
}
