import React from "react";
import { connect } from "react-redux";
// import { HorizontalBar } from "react-chartjs-2";
import { DataGrid } from "@material-ui/data-grid";
import {
  // Container,
  // Grid,
  Typography,
  Button,
  Tooltip,
  CircularProgress,
} from "@material-ui/core";
import {Spinner, AsyncButton} from "@ses-education/courses-components";
import Error from "../../../../template/Error";
import WithTranslations from "../../../../WithTranslations";
import {
  Delete as DeleteIcon,
  SkipPrevious,
  ChevronRight,
  ChevronLeft,
  Check,
  // Cached,
  ArrowRightAlt,
} from "@material-ui/icons";
import "../../courses.scss";
import ProgressService from "../../../../../services/progress";
import { showModal } from "../../../../../redux/modalActions";
import { Link } from "react-router-dom";
import "./progress.scss";
import { buildLink, strFormat } from "../../../../../utils/functions";
// import datagridLocales from "../../../../../data/datagrid-locales";
import dataGridLocalize from "../../../../../data/datagrid-localize";
import { showMessage } from "../../../../../redux/notificationActions";

// const options = {
//   maintainAspectRatio: false,
//   title: {
//     display: true,
//     text: "Course Progress",
//     fontSize: 25,
//   },
//   legend: {
//     position: "top",
//   },
//   scales: {
//     xAxes: [
//       {
//         ticks: {
//           beginAtZero: true,
//           max: 100,
//         },
//       },
//     ],
//   },
// };

const defaultState = {
  stats: null,
  course_progress: null,
  loading: false,
  // data: {
  //   labels: [],
  //   datasets: [
  //     {
  //       label: "Experiment Progress",
  //       data: [],
  //       backgroundColor: "rgba(255, 99, 132, 0.6)",
  //       borderColor: "rgba(255, 99, 132, 1)",
  //       borderWidth: 1,
  //     },
  //     {
  //       label: "Summary Result",
  //       data: [],
  //       backgroundColor: "rgba(54, 162, 235, 0.6)",
  //       borderColor: "rgba(54, 162, 235, 1)",
  //       borderWidth: 1,
  //     },
  //   ],
  // }, //chart data can't be null
  // options: { ...options },
  error: null,
};

const ProgressIndicator = ({ value }) => (
  <span className={`progress-indicator value${value}`}>
    {value === 2 ? <Check /> : value === 1 ? <ArrowRightAlt /> : ""}
  </span>
);

const ProgressHeader =
  (title, align = "center") =>
  () =>
    <span className={`custom-header align-${align}`}>{title}</span>;

class Progress extends React.Component {
  state = {
    ...defaultState,
    // stats: null,
    // course_progress: null,
    // loading: false,
    // data: {}, //chart data can't be null
    // options: {},
    // error: null,
  };

  async componentDidMount() {
    console.log("componentDidMount - this.props: ", this.props);
    const { course_id } = this.props.course;

    this.setState({ loading: true });
    await this.fetchProgress(course_id);
    this.props.fetchTranslations([
      "Error",
      "Course Progress",
      "Back to program",
      "Continue course",
      "Experiment title",
      "Objectives",
      "Discussion",
      "Preparation Questions",
      "Experiment",
      "Summary",
      "Final score",
      "Unknown critical error. Please try reloading the page",
      "Course ID not found!",
      "Unknown error",
      "Unknown error. Please, reload the page",
      "You have finished the course!",
      "Your total course score is <span>%score%</span> out of 100",
      "<span>%progress%%</span> of the course finished"
    ]);
  }

  async componentDidUpdate(prevProps) {
    if (this.props.course !== prevProps.course) {
      await this.fetchProgress(this.props.course.course_id);
    }
  }

  async fetchProgress(id) {
    try {
      const stats = await ProgressService.getCourseStats(id);

      if (!stats) {
        // this.setState({ error: "Fetching progress failed" });
        this.setState({ error: ProgressService.error });
        return;
      }

      let course_progress = await ProgressService.getCourseProgress(id);

      if (!course_progress) {
        this.setState({ error: ProgressService.error });
        return;
      }

      this.setState({ loading: false, stats, course_progress });
    } catch (e) {
      // console.error("resetLastExperiment error:", e);
      this.setState({
        loading: false,
        error: this.props._t(
          "Unknown critical error. Please try reloading the page"
        ),
      });
    }
  }

  async resetProgress() {
    const id = this.props.course ? this.props.course.course_id : null;

    if (!id) {
      console.error(this.props._t("Course ID not found!"));
      return;
    }

    this.setState({ loading: true });

    const result = await ProgressService.resetCourseProgress(id);

    if (result) {
      // fetch data again
      await this.fetchProgress(id);
      this.setState({ loading: false });
      return;
    }

    this.setState({
      loading: false,
      error: ProgressService.error || this.props._t("Unknown error"),
    });
  }

  async resetLastExperiment() {
    const { _t, course, onShowMessage } = this.props;
    const id = course ? course.course_id : null;

    

    try {
      if (!id) {
        // console.error(this.props._t("Course ID not found!"));
        throw new Error(_t("Course ID not found!"))
      }
  
      // this.setState({ loading: true});

      await ProgressService.resetLastExperiment(id);
    
      // fetch data again
      await this.fetchProgress(id);
      // this.setState({ loading: false });
      return;
  
    }catch(e){
      onShowMessage(e.message || _t("Unknown error"), "error");
    }
    
  }

  render() {
    const { data, options, error, loading, stats, course_progress } =
      this.state;
    const {
      user,
      language,
      organization,
      prog_id: program,
      course,
      translationsLoaded,
      _t,
    } = this.props;

    // const numOfExp = stats ? stats.length : 4;
    const columns = [
      // { field: "id", flex: 1, hide: true },

      { field: "id", hide: true },
      {
        field: "title",
        flex: 1,
        headerName: _t("Experiment title"),
        sortable: false,
      },
      {
        field: "objectives",
        flex: 1,
        headerName: _t("Objectives"),
        renderCell: ProgressIndicator,
        renderHeader: ProgressHeader(_t("Objectives")),
        sortable: false,
      },
      {
        field: "discussion",
        flex: 1,
        headerName: _t("Discussion"),
        renderCell: ProgressIndicator,
        renderHeader: ProgressHeader(_t("Discussion")),
        sortable: false,
      },
      {
        field: "prep",
        flex: 1,
        headerName: _t("Preparation questions"),
        renderCell: ProgressIndicator,
        renderHeader: ProgressHeader(_t("Preparation Questions")),
        sortable: false,
      },
      {
        field: "experiment",
        flex: 1,
        headerName: _t("Experiment"),
        renderCell: ProgressIndicator,
        renderHeader: ProgressHeader(_t("Experiment")),
        sortable: false,
      },
      {
        field: "summary",
        flex: 1,
        headerName: _t("Summary"),
        renderCell: ProgressIndicator,
        renderHeader: ProgressHeader(_t("Summary")),
        sortable: false,
      },
      {
        field: "overall_progress",
        flex: 1,
        headerName: _t("Final score"),
        renderCell: ({ value, row }) => (
          <span className="final-score">
            {value < 100 ? `${value}%` : `${row.summary_progress}`}
          </span>
        ),
        sortable: false,
      },
    ];
    const { total_score, progress_percent } = course_progress || {};

    const courseIsFinished =
      course_progress && course_progress.status === "done";

    // console.debug('Program: ', program, 'props:', this.props);

    const linkToParentProgram = buildLink({ organization, program });

    // const courseCurrentPage = `/program/${prog_id}/course/${course.course_id}/experiment/${course.current_experiment_id}`;
    const courseCurrentPage = buildLink({
      organization,
      program,
      course: course.course_id,
      slug: "experiment",
      experiment: course.current_experiment_id,
    });
    if (!translationsLoaded) return <Spinner text="Loading translations..." />;

    if (loading) return <Spinner text="Loading progress..." />;

    if (error || !Array.isArray(stats))
      return (
        <Error
          title={_t("Error")}
          error={error || _t("Unknown error. Please, reload the page")}
        />
      );

    // get localized table texts
    const dataGridLocale = dataGridLocalize(language || "en");

    // parse text with score and percentage
    const totalScoreText = strFormat( 
      _t(
        "Your total course score is <span>%score%</span> out of 100"
      )
     ,
      { "%score%": total_score || "0" }
    );
    console.debug("totalScoreText", totalScoreText);

    
    const progressPercentText = 
    strFormat(
      _t("<span>%progress%%</span> of the course finished"),
      { "%progress%": progress_percent || "0" }
    )

    return (
      <div id="progress" className="mb-20">
        <Typography variant="h2" color="primary">
          {_t("Course Progress")}
        </Typography>
        <DataGrid
          className="data-table"
          rows={stats}
          // rows={[]}
          columns={columns}
          autoHeight
          localeText={dataGridLocale}
          disableSelectionOnClick
          // disableColumnFilter
          // disableColumnMenu
        />
        {course_progress && (
          <div className="course-score">
            {
              // show congratulations when course is done
              courseIsFinished && (
                <>
                  <Typography variant="h6" color="secondary">
                    {_t("You have finished the course!")}
                  </Typography>
                  <div
                    className="score"
                    dangerouslySetInnerHTML={{
                      __html: totalScoreText,
                    }}
                  ></div>
                </>
              )
            }
            {!courseIsFinished && (
              <>
                <div
                  className="progress"
                  dangerouslySetInnerHTML={{
                    __html: progressPercentText,
                  }}
                ></div>
                <div
                  className="score"
                  dangerouslySetInnerHTML={{
                    __html: totalScoreText,
                  }}
                ></div>
              </>
            )}
          </div>
        )}
        <div className="progress-actions">
          <Button
            variant="contained"
            color="primary"
            size="small"
            startIcon={<ChevronLeft />}
            component={Link}
            to={linkToParentProgram}
          >
            {_t("Back to program")}
          </Button>
          {
            // only show 'continue' button when course is not done
            !courseIsFinished && (
              <Button
                component={Link}
                to={courseCurrentPage}
                variant="contained"
                color="primary"
                size="small"
                endIcon={<ChevronRight />}
              >
                {_t("Continue course")}
              </Button>
            )
          }
        </div>

        {user && user.credentials.includes("tester") && (
          <div className="progress-actions">
            <Tooltip
              title={
                <>
                  <span className="progress-tooltip-text">
                    Resets last experiment progress to start or, if last
                    experiment progress is less than 3 pages deep, resets
                    previous experiment to start.
                  </span>
                </>
              }
              placement="bottom"
              arrow
            >
              <AsyncButton
                onClick={() => this.resetLastExperiment()}
                variant="contained"
                color="secondary"
                size="small"
                icon={<SkipPrevious />}
                loadingIcon={<CircularProgress className="loading-icon" style={{height: "none", width: "none"}} color="inherit"/> }

              >
                Reset last experiment
              </AsyncButton>
            </Tooltip>
            <Button
              onClick={() => this.resetProgress()}
              variant="contained"
              color="secondary"
              size="small"
              startIcon={<DeleteIcon />}
            >
              Reset all progress
            </Button>
          </div>
        )}
      </div>
    );
  }
}

const stateToProps = (state) => {
  const { user, language } = state.root;

  return { user, language };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onShowModal: (header, text, buttons) =>
      dispatch(showModal(header, text, buttons)),
    onShowMessage: (message, type) => dispatch(showMessage(message, type))
  };
};

export default WithTranslations(
  connect(stateToProps, mapDispatchToProps)(Progress)
);
