import ActionTypes from './actionTypes';

export const showModal = (header, text, buttons, modalProps = {}) => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.SHOW_MODAL,
      payload: { header, text, buttons, modalProps }
    });
  };
};

export const closeModal = () => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.CLOSE_MODAL,
    });
  };
};
