import React from 'react';
import { Button, Grid } from '@material-ui/core';
import { Link } from 'react-router-dom';
import ProgressService from '../../../../../services/progress';
import { buildLink } from '../../../../../utils/functions';
import './preface.scss';
import WithTranslations from '../../../../WithTranslations';

class Preface extends React.Component {
  state = {
    btnText: 'Continue learning',
  };

  componentDidMount() {
    const {placeholder_text = 'Welcome to this course.'} = this.props;
    this.props.fetchTranslations([placeholder_text, 'Get started', 'Continue learning']);
    this.fetchProgress();
  }

  async fetchProgress() {
    const result = await ProgressService.getCourseProgress(
      this.props.course.course_id
    );
    console.log('Preface - result: ', result);

    // if (result.status === 'open' && parseInt(result.progress_percent) >= 0) {
    //   this.setState({ btnText: 'Continue learning' });
    // }
    if(!result || (result && !result.progress_percent)){
      this.setState({ btnText: 'Get started' });
    }
  }

  markup = (html) => {
    return { __html: html };
  };

  render() {
    let {html} = typeof this.props.course === "object" ? this.props.course : {};
    // remove image for html and save image to variable image
    let parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');
    const image = (doc.getElementsByTagName('img')[0] && doc.getElementsByTagName('img')[0].outerHTML) || '';
    if(doc.getElementsByTagName('img')[0]){ 
      doc.getElementsByTagName('img')[0].remove();
      html = doc.body.outerHTML;
    }
    const {
      placeholder_text = 'Welcome to this course.',
      organization,
      prog_id: program,
      course = {},
      _t,
    } = this.props;

    const courseLink = buildLink({
      organization,
      program,
      course: course.course_id,
      slug: 'experiment',
    });
    return (
      <div className='course-preface'>
        <Grid container spacing={2}>
          <Grid item xs={8}>
            {html ? (
              <div dangerouslySetInnerHTML={this.markup(html)}></div>
            ) : (
              <div className='placeholder-text'>{_t(placeholder_text)}</div>
            )}
            <div className='button-wrapper'>
              <Button
                component={Link}
                to={courseLink}
                variant='contained'
                color='primary'
              >
                {_t(this.state.btnText)}
              </Button>
            </div>
          </Grid>
          <Grid item xs={4} style={{minWidth:"min-content"}}>
            <div dangerouslySetInnerHTML={{__html:image}}></div>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default WithTranslations( Preface );
