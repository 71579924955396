import Programs from '../components/programs';
import Courses from '../components/courses-list';
import Course from '../components/courses/item';
// import Catalog from '../components/courses/catalog';
import AboutUs from '../components/AboutUs';
// import Support from '../components/Support';
import P404 from '../components/template/Page404';
import Organizations from '../components/Organizations';
import Profile from '../components/Profile';
import Test from '../components/test';
import Tests from '../components/tests';
import {
  Preface,
  Progress,
  // FormulaSheet,
  Course as CourseMain,
  // Guidance,
} from '../components/courses/item/pages';

// Wow, I don't remember why I needed this!!
// const components = {
//   Organizations,
//   Programs,
//   Courses,
//   Course,
//   Catalog,
//   AboutUs,
//   Profile,
//   // Support,
//   Test,
//   P404,
// };

// TODO:  .default at the end is needed due to the batch export via courses/item/pages/index.
// may need to refactor this
export const courseSubroutes = [
  { link: 'preface', title: 'Preface', component: Preface.default },
  { link: 'experiment', title: 'Course', component: CourseMain.default },
  { link: 'progress', title: 'Progress', component: Progress.default },
  // {
  //   link: 'formula-sheet',
  //   title: 'Formulas',
  //   component: FormulaSheet.default,
  // },
  // { link: 'guidance', title: 'Guidance', component: Guidance.default },
];

// the list of available subroutes, joined for usage in route regex
const courseSubrouteList = courseSubroutes
  .filter((r) => r.link)
  .map((r) => r.link)
  .join('|');

export const routes = [
  {
    exact: true,
    path: '/',
    // component: components['Organizations'],
    component: Organizations,
    
  },
  {
    exact: true,
    path: '/about',
    // component: components['AboutUs'],
    component: AboutUs,
    
  },
  {
    exact: true,
    path: '/profile',
    // component: components['Profile'],
    component: Profile
  },
  {
    exact: true,
    path: '/:organization',
    // component: components['Programs'],
    component: Programs,
    
  },
  {
    exact: true,
    path: `/:organization/program/:prog_id/course/:id`,
    // component: components['Course']    
    component: Course    
  },
  
  {
    exact: true,
    path: `/:organization/program/:prog_id/course/:id/:slug`,
    // component: components['Course'],
    component: Course,
  },  
  {
    exact: true,
    path: `/:organization/program/:prog_id/course/:id/:slug/:exp_id`,
    // component: components['Course'],
    component: Course,
  },
  {
    exact: true,
    path: `/:organization/program/:prog_id/course/:id/:slug/:exp_id/:chapter`,
    // component: components['Course'],
    component: Course,
  },
  // {
  //   exact: true,
  //   path: `/:organization/program/:prog_id/course/:id/:slug(${courseSubrouteList})?/:exp_id?/:chapter?`,
  //   component: components['Course'],
  // },
  {
    exact: true,
    path: '/:organization/program/:prog_id',
    // component: components['Courses'],
    component: Courses
  },

  // Test route that is available only for tester users
  {
    exact: true,
    path: '/test/*',
    component: Test    
  },

  {
    exact: true,
    path: '/tests',
    component: Tests    
  },

  // { exact: false, path: '*', component: components['P404'] },
  { exact: false, path: '*', component: P404 },
];

export default routes;
