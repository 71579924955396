import A from './actionTypes';
const localStorageKey = 'SEScoursesNavigations';

let localStorageState;
try {
    localStorageState = localStorage.getItem(localStorageKey)
      ? JSON.parse(localStorage.getItem(localStorageKey))
      : {};
  } catch (e) {
    localStorageState = {};
  }  
const initialState = {
    ...{
    user_course_navigation: []
    },
    ...localStorageState,
 };

 export default function root(state = initialState, action) {
    let newState = { ...state };
    const { type, payload} = action;
    switch (type) {
        case A.SET_CURRENT_PROGRAM_COURSE:
            const {program_id,current_course,user_id}=payload;
            const finduserfunc=(item)=>(item.user_id===user_id);
            const findprogramfunc=(item)=>(item.program_id===program_id);
            const finduser=state.user_course_navigation.find(finduserfunc);
            if(!finduser){
                newState.user_course_navigation.push({user_id,programs:[{program_id,current_course}]});
            }
            else{ 
                const findprogram=finduser.programs.find(findprogramfunc);
                if(!findprogram)
                    newState.user_course_navigation.find(finduserfunc).programs.push({program_id,current_course});
                else if(current_course!=findprogram.current_course){
                    newState.user_course_navigation.find(finduserfunc).programs.find(findprogramfunc).current_course=current_course;
                }   
            }

            break;
            
        default:
            //newState = state;
    }
    localStorage.setItem(localStorageKey, JSON.stringify(newState));
    return newState;
 }