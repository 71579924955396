import { Button, FormControlLabel, FormGroup, Switch, Typography } from '@material-ui/core'
import React from 'react'
import { connect } from 'react-redux';
import { closeModal, showModal } from '../../redux/modalActions';


class TesterTools extends React.Component{

    render(){
        const {
            reduxUser, toggleHardware, allowNoHardwareExperiment,
            showModal,
            closeModal
        } = this.props;

        return( 
        <>
        <FormGroup>
            <Typography variant="h3">Tester tools</Typography>
            <FormControlLabel
            label="EB3000 connection required: "
            labelPlacement="start"
              control={
                <Switch
                  onClick={() => toggleHardware()}
                  checked={ !allowNoHardwareExperiment}
                  />
              }
            />
          </FormGroup>
          <Button variant="contained" color="primary"
          onClick={() => showModal(
              "Test modal window", "test modal content", [
                  { text: "Ok" },
                  { text: "Cancel", buttonProps:{color: "secondary"} },
              ]
          )}
          >Test modal window</Button>
          </>
          )
    }
}

const stateToProps = (state) => ({
    ui: state.ui
})

const mapDispatchToProps = (dispatch) => ({
    showModal: (header, text, buttons) => dispatch( showModal(header, text, buttons) ),
    closeModal: () => dispatch( closeModal )
})
export default connect(
    stateToProps,
    mapDispatchToProps
)( TesterTools)