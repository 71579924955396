import { Typography } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import React from "react";
import {Spinner} from "@ses-education/courses-components";
// import Error from "../../../../template/Error";

let CheckTimer;

const DefaultConnectionError = (
  <Alert className="inline-alert center" severity="error">
    <AlertTitle>Error communicating to D-Scope software</AlertTitle>
    <Typography>
      The D-Scope software failed to reply, probably it is not running.
    </Typography>
    <Typography>Please, start the D-Scope software.</Typography>
    <Typography>
      If it is already running, try closing it and starting again.
    </Typography>
  </Alert>
);

const DefaultNotConnected = (
  <Alert className="inline-alert center" severity="error">
    <AlertTitle>EB3000 is not connected</AlertTitle>
    <Typography>
      You cannot continue without connection to EB3000 device.
    </Typography>
    <Typography>
      Please, connect EB3000 to the computer via USB cable
    </Typography>
  </Alert>
);

/**
 * A wrapper component that periodically checks for aan external function to return true,
 * and if the result is not true shows errors
 */
class HardwareConnector extends React.Component {
  state = {
    hardwareConnected: null,
    error: null,
  };

  componentDidMount() {
    if (this.props.active) {
      console.debug("hc mounted and active");
      this.startCheckingConnection();
    } else {
      console.debug("hc mounted and NOT active");
    }
  }

  componentDidUpdate(prevProps) {
    const { active } = this.props;
    const { active: prev_active } = prevProps;

    console.debug("hc updated", active, prev_active, CheckTimer);
    if (active !== prev_active) {
      if (active && !CheckTimer) {
        console.debug("hs is active and checktimer empty")
        this.startCheckingConnection();
      }
      else if (!active && CheckTimer) {
        console.debug("hs is NOT active and checktimer NOT empty")
        this.stopCheckingConnection();
      }
    }
  }

  componentWillUnmount() {
    console.debug("hc will unmount");
    // don't check connection when not mounted
    this.stopCheckingConnection();
  }

  startCheckingConnection = async () => {
    const { checkDelay = 10000 } = this.props;
    console.debug("hc starting checking connection...");
    await this.checkConnection();
    console.debug("hc connection checked");
    CheckTimer = setInterval(this.checkConnection, checkDelay);
  };

  stopCheckingConnection = () => {
    clearInterval(CheckTimer);
    CheckTimer = null;
    console.debug("hc interval cleared:", CheckTimer);
  };

  checkConnection = async () => {
    const { connectionChecker } = this.props;

    if (typeof connectionChecker !== "function") {
      this.setState({
        hardwareConnected: null,
        error: "Connection check method unavailable!",
      });
      return;
    }
    try {
      console.debug("hc calling connection checker");
      const hardwareConnected = await connectionChecker();
      console.debug(
        "hc connection checker result received:",
        hardwareConnected
      );
      this.setState({ hardwareConnected, error: null });
    } catch (e) {
      this.setState({ hardwareConnected: null, error: e.message });
    }
  };

  render() {
    const {
      active,
      children,
      checkingConnectionText = "Checking EB3000 connection",
      ConnectionError = DefaultConnectionError,
      NotConnected = DefaultNotConnected,
    } = this.props;
    const { hardwareConnected, error } = this.state;

    // not active - just do nothing
    if (!active) return children;

    // is active: see how's the connection
    if (hardwareConnected === null && !error)
      return <Spinner text={checkingConnectionText} />;
    if (hardwareConnected === null && error) return ConnectionError;

    // server is up but responds with False - not connected
    if (hardwareConnected === false) return NotConnected;

    return children;
  }
}

export default HardwareConnector;
